:root {
  --primary: #0a4e9b;
  --secondary: #4b82bd;
}

body {
  font-family: Poppins, Helvetica, Arial, sans-serif !important;
}
.full-height {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.sticky-nav {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: white;
}
nav ul li a {
  color: var(--secondary) !important;
  font-size: 17px !important;
  font-weight: 600 !important;
}
.main-logo {
  /* width: 100%;
  height: auto; */
  /* max-width: 360px; */
  /* object-fit: cover; */
  width: 100%;
  /* height: 200px; */
  margin: 0 auto;
}
.navbar-toggler {
  color: black !important;
}
.color-secondary {
  color: var(--secondary);
}
.bg-color-secondary {
  background-color: var(--secondary);
}
.main-menu {
  border: solid 2px #ccc !important;
  border-width: 2px 0 2px 0 !important;
}
.navbar {
  padding: 0 !important;
}
.collpase span {
  white-space: nowrap;
  padding-left: 10px;
}
.collpase {
  cursor: pointer;
  display: flex;
  /* justify-content: space-between; */
  padding: 10px 6px;
  align-items: center;
  font-size: 14px !important;
  font-weight: 600;
  color: white;
  background: var(--primary) !important;
}
.Printing_img {
  height: 280px;
  /* object-fit: cover; */
  display: flex;
  align-items: center;
}

.Printing_img .cat_img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.collpase .btn-secondary {
  background: var(--primary) !important;
  /* outline: none !important; */
  border: none !important;
  border-color: unset !important;
}
.collapse a {
  color: #252525;
}
.collapse a:hover {
  text-decoration: none;
  color: #484848;
}
.collpase .btn {
  padding: 0;
}
.collpase .f-size {
  font-size: 18px !important;
}
.content .menu {
  border: solid 2px #ccc;
}
.content .btn-secondary:focus,
.btn:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none !important;
}
.gallery-btn {
  background: var(--secondary) !important;
  border: none;
  color: #fff !important;
  padding: 7px 20px;
  font-size: 14px;
}

.primary-menu {
  padding: 0;
  list-style: none;
}
.primary-menu li {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-align: left;
  font-weight: 600;
  font-size: 12px !important;
  padding: 9px 12px 0 !important;
  color: #252525;
}
.primary-menu li a {
  color: #252525;
  text-decoration: none;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.home_icon {
  color: #fff;
}
.home_icon:hover {
  color: var(--secondary);
}
.footer ul {
  padding: 0;
  list-style: none;
}
.footer h2 {
  color: #fff !important;
  font-size: 20px !important;
  text-transform: uppercase;
  font-weight: 600 !important;
  margin: 0 0 20px;
}
.footer {
  color: #fff !important;
  background-color: var(--primary);
  /* padding: 10px 50px; */
  font-size: 15px;
  padding: 35px 100px 0px 100px;
}
.footer a {
  color: #fff !important;
}
.footer a:hover {
  text-decoration: none;
}
.footer .footer-menu1 li {
  font-family: Poppins, Helvetica, Arial, sans-serif !important;
  font-weight: 600;
  margin-bottom: 20px;
  margin-left: 0;
  /* float: left !important; */
  font-size: 14px;
  display: flex;
  align-items: center;
}
.footer .footer-menu li {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  border-bottom: solid 2px !important;
  padding: 15px 0 !important;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 500;
}
.footer .footer-menu li:last-child {
  border-bottom: none !important;
}
/* .border-top {
  padding: 10px;
  border-top: solid 1px #999898;
} */
.new_border {
  padding: 10px;
  border-top: solid 2px #999898;
}

.new_border p {
  padding: 10px 0px;
  font-size: 15px;
  font-weight: 500;
}
.title h3 {
  /* margin: 0px auto 5px !important; */
  color: #ed1b20;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 26px;
}
.mycard .card-body {
  background-color: var(--secondary) !important;
}
.title h4 {
  /* margin: 0 auto; */
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 21px;
  color: var(--secondary);
  font-weight: 600 !important;
  margin-bottom: 20px;
}
.title span {
  font-weight: 400;
}
.wanting h5 {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  color: #252525;
  display: block;
  font-size: 13px;
  line-height: 22px;
  margin: 3px 0px !important;
  font-weight: 500;
}
.card h5 {
  color: white !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}
.card-body {
  padding: 8px !important;
}

.p-title {
  display: inline-block !important;
  /* margin: 0 auto !important; */
  font-size: 22px !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
  color: var(--primary) !important;
  margin-bottom: 28px !important;
  line-height: 1;
  position: relative;
  top: 11px;
  font-family: Poppins, Helvetica, Arial, sans-serif;
}
.sms-title {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  /* margin: 0px auto 5px !important; */
  margin-bottom: 40px !important;
  color: #ed1b20;
  font-weight: 600 !important;
  text-transform: uppercase;
}
.sms-title1 {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  /* margin: 0px auto 5px !important; */
  margin-bottom: 13px !important;
  color: #e22122;
  font-size: 30px;
  font-weight: 500 !important;
  /* text-transform: uppercase; */
}
.main-title {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  color: var(--secondary);
  font-size: 20px;
  margin: 10px 0px;
}
.wanting select {
  padding: 4px;
  width: 63%;
  font-size: 17px;
  border: 1px solid #ccc;
  clear: both;
  border-radius: 5px;
  font-weight: 400;
  color: #000;
}
.art {
  margin-bottom: 15px;
  font-family: Poppins, Helvetica, Arial, sans-serif !important;
  color: #5d7281;
  font-size: 20px;
}
.label input {
  /* width: 5% !important; */
  /* float: left; */
  margin: 7px 0 7px 0;
}
.printingtip {
  margin: 18px 0 35px;
  padding-left: 0;
}
.printingtip a {
  margin: 10px 0 30px;
  color: var(--secondary) !important;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
  text-decoration: underline;
}
.label {
  font-family: Poppins, Helvetica, Arial, sans-serif !important;
  color: var(--secondary);
  display: inline-block;
  white-space: nowrap;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  width: 56%;
  font-size: 15px;
  /* float: left; */
}
.y-title {
  color: #5d7281;
  font-weight: 600;
  font-size: 20px;
}
.prdsize {
  margin-bottom: 0;
  padding: 10px;
  color: #3a3a3a;
  font-size: 19px;
  font-weight: 600;
}
.prdsize p {
  text-align: center;
  text-transform: capitalize;
  font-size: 16px;
  /* margin-bottom: 5px; */
}

li:hover .icon {
  border-color: #fff;
}
.icon {
  border: 2px dashed #fff;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  min-width: 55px;
  min-height: 55px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  margin-right: 24px;
}
.icon img {
  max-width: 35%;
  vertical-align: middle;
}
.apus-breadscrumb img {
  width: 100%;
  margin-bottom: 30px;
}

.apus-breadscrumb1 img {
  width: 100%;
  /* height: 190px; */
  margin-bottom: 30px;
}
.new-block {
  padding: 0 30px 30px 0;
  margin: 15px 0;
}
.new-block h4 {
  color: #0d4d9c;
  font-size: 27px;
}
.new-block p {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.75;
  color: var(--secondary);
}
.new-block button {
  color: #fff;
  background-color: var(--primary);
  font-size: 15px;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: none;
}
.new-block button:hover {
  background: white;
  border: solid 2px var(--primary);
  color: var(--primary);
}
.new-block button:active {
  color: #f2f2f2;
  background-color: #f22d27;
}
.cbody {
  font-size: 28px;
  font-weight: 400;
  margin: 0 0 5px;
}
.entrytitle {
  font-size: 28px;
  font-weight: 400;
  margin: 0 0 5px;
}
.autohor {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.75;
  color: var(--secondary);
}
.detail {
  padding-top: 30px;
  border-top: 1px solid #ebebeb;
}
.marginb {
  margin: 0 0 30px;
}
.title-mb {
  margin: 0 0 5px;
}

.cat_width {
  width: 75%;
}

.btn-submit {
  color: white;
  padding: 11px 35px;
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 2px solid transparent;
  white-space: nowrap;
  letter-spacing: 0;
  text-transform: uppercase;
}
.myimg {
  width: 100%;
  height: auto;
}
.mobile_icon,
.mobile_logo {
  display: none;
}
.about p {
  margin: 0 0 13px;
  color: #000;
  line-height: 1.75;
  font-weight: 400;
  font-size: 17px;
}
.entry-tag {
  font-size: 12px;
  text-transform: uppercase;
  padding: 5px 20px;
  color: var(--secondary);
  font-weight: 400;
  display: inline-block;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  background: #f5f5f8;
}
.img-bg {
  margin: 20px auto;
  border: solid 2px #ccc;
  padding: 10px;
  width: 100%;
  text-align: center;
}
.img-style {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}
.icon-style i {
  color: #ccc;
  font-size: 14px;
  margin-right: 20px;
}
.sstyle {
  width: 40%;
  color: #ed1b20;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 35px;
}

.h3style {
  margin: 0 auto;
  color: #ed1b20;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 26px;
}
.b-gallery {
  border: 1px solid #aaa;
  transition: all 0.5s ease 0s;
  position: absolute;
  display: block;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;
  left: 588px;
  top: 0px;
  width: 282px;
  height: 201.429px;
}
.text-gallery {
  color: #000 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  width: max-content !important;
  position: relative;
  top: -27% !important;
}
.text-bold {
  font-weight: 700;
  color: #ed1b20 !important;
}

.myimgstyle {
  padding: 10px;
  border: 1px solid gray;
  margin: 10px 0px;
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.isCatNameTag h4:before {
  width: 0;
  height: 0;
  border-top: 20px solid #ffffff;
  border-bottom: 20px solid #ffffff;
  border-right: 0px solid #7d5f5f;
  content: "";
  position: relative;
  right: 0;
  top: -8px;
  border-left: 20px solid var(--secondary);
  font-size: 0px;
  margin-right: 15px;
}
.catsWrap a:after {
  width: 0;
  height: 0;
  border-top: 10px solid #ffffff;
  border-bottom: 10px solid #ffffff;
  border-right: 0px solid #7d5f5f;
  content: "";
  position: relative;
  right: -8px;
  top: -5px;
  border-left: 10px solid var(--secondary);
  font-size: 0px;
}
.is-active {
  border-bottom: 1px var(--secondary) solid;
}
.nav-item a:hover {
  border-bottom: 1px var(--secondary) solid;
}
/* .nav-item a:hover{
  border-bottom:1px var(--secondary) solid ;
} */
.nav-item a {
  padding: 0px 2px !important;
}
.nav-item {
  padding: 0.5rem !important;
}

.add-fix-top.active {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  opacity: 0.7;
}
.add-fix-top {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  position: fixed;
  z-index: 99;
  bottom: 50px;
  right: 0;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  display: inline-block;
  color: #fff !important;
  opacity: 0;
  width: 45px;
  height: 45px;
  line-height: 40px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  text-align: center;
  background: var(--secondary);
}
.add-fix-top {
  background: var(--secondary) !important;
}
.add-fix-top i {
  font-size: 25px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
}
.prodSingImg {
  height: 280px !important;
  object-fit: cover;
}
.cateogoryDescp {
  color: #000;
  font-size: 15px;
  padding: 15px;
}
.cateogoryDescp p {
  margin: 0 0 13px;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  /* font-size: 15px; */
  font-weight: 400;
  line-height: 1.75;
}
.list:hover {
  color: #fff;
  border-color: #121212;
  background: #121212;
}
.widget-text-heading.center {
  text-align: center;
}
.cnth3 h3.title {
  font-size: 30px;
}
.widget-text-heading .title {
  /* font-size: 36px; */
  font-weight: 400;
  margin: 0 0 5px;
}
.widget-text-heading .des {
  font-size: 13px;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: var(--secondary);
}
.location-inner {
  text-align: center;
  padding: 10px;
}
.location-content a {
  text-decoration: none;
  color: var(--secondary);
}
.location-inner .fbox-icon {
  margin-bottom: 20px;
}
.location-inner .title {
  font-weight: 400;
  margin: 0 0 5px;
  color: #252525;
  font-size: 18px;
}
.location-inner {
  text-align: center;
  padding: 10px;
}
/* .description {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.75;
  color: var(--secondary); */
.description {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.75;
  color: var(--secondary);
}
.description1 {
  white-space: nowrap;
}

.size_margin {
  margin-left: 16px;
}
.navbar-toggler {
  padding: 0;
  margin-top: 5px !important;
}

/* @media only screen and (min-width: 992px) {
  .prodSingImg {
    height: 280px !important;
  }
} */
@media only screen and (max-width: 1412px) {
  .collpase span {
    white-space: break-spaces;
    padding-left: 10px !important;
  }
}

@media screen and (max-width: 1126px) and (min-width: 990px) {
  .footer {
    padding: 35px 20px 0px 20px;
  }
  .Printing_img {
    height: 230px;
    /* object-fit: cover; */
    display: flex;
    align-items: center;
  }
  .map {
    width: 230px !important;
    /* height: 0px !important; */
  }
}

@media screen and (min-width: 1200px) {
  .instant-price-main > .col-lg-4 {
    flex: 0 0 28%;
    max-width: 28%;
  }
  .instant-price-main > .col-lg-8 {
    flex: 0 0 72%;
    max-width: 72%;
  }
  .container {
    max-width: 1200px;
  }
  /* .Printing_img{
    height: 200px;
  } */
}

@media screen and (max-width: 1098px) {
  .apus-breadscrumb1 img,
  .apus-breadscrumb img {
    height: auto;
  }
}

select option:disabled {
  color: red !important;
  /* font-weight: bold; */
}

@media screen and (max-width: 1250px) {
  .myimgstyle {
    height: 250px;
  }
}
@media only screen and (min-width: 600px) {
  /* .container{
    max-width: 100%;
  } */
  .prodSingImg {
    height: 280px !important;
  }
  .wanting_btn {
    text-align: center;
  }
  .new_border {
    padding: 10px;
    border-top: solid 2px;
    color: #999898;
    width: 70%;
    text-align: center;
    margin: auto;
  }
  .mobile-close {
    display: block !important;
  }
  /* .map{
    width: "300px";
    height: auto;
  } */
}
/* @media (min-width: 576px) {
.want_width{
  width:50%;
 }
} */
.btn-align {
  /* width:50%; */
  text-align: center;
}
.user_form_width {
  width: 60%;
}
.user_form_btn {
  width: 60%;
  text-align: center;
}
@media only screen and (max-width: 990) {
  /* .user_form_width{
    width:70%
  }
  .user_form_btn{
    width:120%;
    text-align: center;
  } */

  .logo_display {
    display: none !important;
  }

  .faq_main_title {
    font-size: 30px;
    color: rgb(231, 32, 41);
    margin: 0px 0px 10px;
  }
  .footer {
    color: #999fa4 !important;
    background-color: var(--primary);
    /* padding: 10px 50px; */
    font-size: 15px;
    padding: 35px 10px 0px 10px !important;
  }
}

@media only screen and (max-width: 768px) and (min-width: 990px) {
  .Printing_img {
    height: 180px !important;
  }
}

/* @media only screen and (max-width: 768px) {
  .map {
    width: 100% !important;
    height: auto;
  }
} */

.main-block.col-9 {
  flex: 0 0 95% !important;
  max-width: 95% !important;
}
.find {
  margin-left: 10px !important;
}

.btn_base_style {
  background: var(--secondary) !important;
  border: none !important;
  color: #fff !important;
  padding: 7px 20px !important;
  border-radius: 5px !important;
  font-size: 15px;
}

.size_h3 {
  color: #e22122;
  font-size: 30px;
  /* margin-top: 26px; */
  margin-bottom: 13px;
}

.size_h4 {
  color: #5d7281;
  font-size: 20px;
  margin-top: 13px;
  margin-bottom: 13px;
}

.textarea {
  /* margin: 0 auto; */
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 20px;
  color: var(--secondary);
  font-weight: 400 !important;
  /* margin-bottom: 20px; */
}

.img_txt {
  padding: 15px 0 !important;
  text-transform: uppercase;
}
.aw_title {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.3 !important;
  color: #252525;
  margin-top: 26px !important;
  margin-bottom: 13px !important;
  font-size: 26px;
}
.aw_headtitle {
  padding: 26px 0 !important;
}

.aw_desc {
  font-size: 15px !important;
  font-weight: 400;
  line-height: 1.75 !important;
  color: var(--secondary) !important;
}

.aw_subtitle {
  font-size: 19px !important;
  margin: 13px 0 !important;
  color: #252525;
}

.aw_border {
  text-align: center;
  border: dotted 1px !important;
  border-radius: 0 !important;
  height: calc(100% - 35px);
  /* height: 230px; */
  padding: 10px;
}
.aw_images {
  width: auto !important;
  margin: 0 auto !important;
  text-align: center !important;
  height: 150px !important;
  overflow: hidden !important;
  max-width: 100%;
}
.aw_cardlink {
  padding-top: 10px !important ;
  text-decoration: none;
  color: #252525;
  font-weight: 600;
  font-size: 15px;
}

/* @media only screen and */
@media (max-width: 768px) and (min-width: 1200px) {
  .img_width {
    max-width: 300px !important;
  }
  .aw_images {
    max-width: 100% !important;
    width: 100%;
  }
}

.faq_main_title {
  font-size: 30px;
  color: rgb(231, 32, 41);
  margin: 30px 0px;
}
.card_margin {
  margin-bottom: 35px;
}

.nav_active {
  display: block;
}
.nav_inActive {
  display: none;
}

a.aw_cardlink:hover {
  color: var(--secondary) !important;
  outline: 0 !important;
  text-decoration: none !important;
}
ul {
  padding-left: 0;
  /* padding-right: 25px; */
}

li.vc_tta-tab {
  background: var(--primary);
  border: none !important;
  color: #fff !important;
  border-bottom: solid 3px #fff !important;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}

li.vc_tta-tab a {
  color: #ffffff;
}

li.vc_tta-tab a:hover {
  text-decoration: none;
}

li.vc_tta-tab.vc_active {
  background: #0e4d99;
}

span.vc_tta-title-text {
  font-size: 17px !important;
  text-transform: uppercase !important;
  /* padding: 0 30px!important; */
}

.content_elipsis {
  overflow: hidden !important; /* or overflow: scroll; */
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  display: block;
}

/* .faq-title {
    cursor: pointer;
    color: #252525;
    /* padding: 5px 30px 5px 0!important; */
/*font-size: 20px;
  } */

.faq-accordion .faq-main {
  border: 1px solid #ffffff;
  margin-bottom: 15px;
  padding: 2px;
  transition: all 0.5s ease 0s;
}

.faq-main {
  background: #ffffff;
  margin-bottom: 15px;
}

.accord_item_border {
  border: 1px solid rgba(102, 102, 102, 0.04);
  padding-bottom: 36px;
  border-radius: 5px;
  margin: 1px 0;
}

.faq-title h4 {
  margin-left: 2px;
  width: 90%;
  position: relative;
  margin: 0 !important;
  font-size: 20px !important;
  line-height: 22px !important;
  padding: 2px !important;
  text-align: left;
}
.accordion-button {
  position: relative;
  width: 100%;
}
.accordion-button:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 12px;
  font-size: 25px;
  font-weight: 200;
  color: #444;
  height: 15px;
  width: 24px;
  /* background: url(https://wholesale-magnets.com.au/wp-content/plugins/sp-faq/assets/images/down.png)
    right 0 no-repeat; */
  background: url("../siteImages/down.png") right 0 no-repeat;
  background-size: 50%;
  /* transform: rotate(180deg); */
}

.accordion-button:not(.collapsed)::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 25px;
  font-weight: 200;
  color: #444;
  height: 15px;
  width: 24px;
  /* background: url(https://wholesale-magnets.com.au/wp-content/plugins/sp-faq/assets/images/down.png)
    right 0 no-repeat; */
  background: url("../siteImages/down.png") right 0 no-repeat;
  background-size: 50%;
  color: #e72029 !important;
  transform: rotate(180deg);
  margin-bottom: 5px;
}
.editor-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.news-main-div {
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto;
}

.editor-display img {
  width: 300px;
}

/* (max-width:1200px) and */
@media only screen and (max-width: 990px) {
  /* .faq_full_content {
    padding: 0 80px;
  } */
  /* .main-logo {
    display: none;
  } */
  .mobile_icon,
  .mobile_logo {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40px;
    max-width: initial;
  }
  .main-menu {
    border: 0 !important;
    position: relative;
    /* left: 0;
    right: 0;
    top: 10px; 
    width: 100%;*/
    margin-top: -57px;
    margin-inline: -20px;
  }
  .logomobile {
    max-width: 270px;
    transform: translateX(9%);
  }
  .sticky-nav {
    border-bottom: solid 1px #ccc !important;
    /* padding-bottom: 10px; */
  }
  /* nav.navbar.navbar-expand-lg.navbar-light {
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 65px;
    margin-right: -25%;
    margin-left: -30%;
  } */
}

.faq-main {
  border-bottom: solid 1px var(--primary) !important;
  padding-bottom: 15px !important;
}

.faq-content {
  color: #000000;
  font-size: 17px;
  /* padding: 0; */
  font-family: "Poppins";
}

.faq-content table > tbody > tr > td {
  border: 1px solid #ddd;
  padding: 8px;
  line-height: 1.75;
  vertical-align: top;
  white-space: nowrap;
}

.faq_nav {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  padding: 14px 20px;
  border: 1px solid rgba(102, 102, 102, 0.04);
  border-radius: 5px;
  background: #f8f8f8;
  margin-bottom: 1px;
}
.faq_nav .faq_nav_title {
  font-size: 17px;
  padding: 0 30px;
  font-weight: 500;
  color: #666;
}

.accordion-button.collapsed .faq-title:after {
  transform: rotate(0deg);
}

.accordion-button {
  background: none;
  border: none;
  padding-left: 0;
  color: #252525;
}

.accordion-button:not(.collapsed) {
  color: #e72029 !important;
}

.accd_mr_btm {
  margin-bottom: 100px;
}

@media only screen and (max-width: 768px) {
  .vc_tta-tabs-list {
    display: none;
  }
  .faq_nav {
    display: block;
  }
  .accd_mr_btm {
    margin-bottom: 0;
  }
  .cat_width {
    width: 100% !important;
  }
  .map {
    width: 100% !important;
    height: auto;
  }
  .footer {
    padding: 35px 20px 0px 20px;
  }
  .user_form_width {
    width: 100%;
  }
  .user_form_btn {
    width: 100%;
  }
  .isCatNameTag h4:before {
    width: 0;
    height: 0;
    border-top: 14px solid #ffffff;
    border-bottom: 14px solid #ffffff;
    border-right: 0px solid #7d5f5f;
    content: "";
    position: relative;
    right: 0;
    top: -8px;
    border-left: 20px solid var(--secondary);
    font-size: 0px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .Printing_img {
    height: auto;
    /* object-fit: cover; */
    display: flex;
    align-items: center;
  }

  .Printing_img .cat_img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .wanting_btn {
    text-align: left;
  }

  .size_h3 {
    font-size: 28px;
  }
  .myimgstyle {
    height: auto;
  }
  .map {
    width: 100% !important;
    height: auto;

    /* height: 0px !important; */
  }
  .title h3 {
    font-size: 20px;
  }
  .title h4 {
    font-size: 17px;
  }
  .title {
    margin: 15px 0px;
  }
  .collpase {
    padding: 10px 12px;
  }

  .prodSingImg {
    height: auto !important;
  }
  .mobile-close {
    display: none !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .want_width {
    width: 100% !important;
  }
  .size_margin {
    margin-left: 0 !important;
  }
  .user_form_width {
    width: 100%;
  }
  .btn-align {
    text-align: left;
  }
  .user_form_btn {
    text-align: center;
    width: 100%;
  }
  .gallery-btn {
    margin-top: 40px;
  }
  .user_form_btn {
    text-align: left;
    width: 100%;
  }
}
@media only screen and (max-width: 400px) {
  .isCatNameTag h4:before {
    width: 0;
    height: 0;
    border-top: 14px solid #ffffff;
    border-bottom: 14px solid #ffffff;
    border-right: 0px solid #7d5f5f;
    content: "";
    position: relative;
    right: 0;
    top: -8px;
    border-left: 20px solid var(--secondary);
    font-size: 0px;
    margin-right: 15px;
  }
  .faq_nav_title {
    padding: 0 !important;
  }
  .size_h3 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 768px) {
  .faq_nav {
    display: none;
  }
}

.nav_link_space {
  padding-left: 15px;
  background-color: rgba(248, 248, 248, 0.7);
}
.article-item__title {
  margin-bottom: 0;
  font-size: 18px;
  color: #333;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 22px;
  margin-top: 30px;
}
.article-item__excerpt {
  margin-top: 21px;
  font-size: 15px;
  color: #333;
  letter-spacing: 0px;
  line-height: 24px;
  word-break: break-word;
}
.img-contain {
  width: 100%;
  height: 215px;
  object-fit: contain;
  object-position: top;
  background-color: #f1f1f1;
}
/* .wanting select {
  padding: 4px;
  width: 63%;
  font-size: 17px;
  border: 1px solid #ccc;
  clear: both;
  border-radius: 5px;
  font-weight: 400;
  color: #000;
} */
